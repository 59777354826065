import { getCurrentTimezone, getLanguageMiddleware } from '~/constants/setting'

export default defineNuxtRouteMiddleware(async () => {
  const userInfo = await getUserInfo()
  if (isFirstLogin(userInfo)) {
    const userTimeZone = getCurrentTimezone()
    const userLanguage = getLanguageMiddleware()
    update_user({ language: userLanguage, timezone: userTimeZone })
    return
  }

  setLocale(userInfo?.language)
})

function setLocale(languageCode: string | null) {
  if (languageCode) {
    const { $i18n } = useNuxtApp()
    $i18n.setLocale(languageCode)
  }
}

function isFirstLogin(userInfo: any) {
  if (userInfo)
    return false

  else
    return true
}

async function getUserInfo() {
  const data = await check_exist_user() as any
  if (!data)
    return null
  const checkLanguage = data.language
  const checkTimeZone = data.timezone
  if (checkLanguage && checkTimeZone) {
    return {
      language: checkLanguage,
      timezone: checkTimeZone,
    }
  }
  else {
    return null
  }
}
